.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.custom-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow-y: scroll;
  height: 800px; /* set a fixed height */

  overflow-y: auto; /* Enable horizontal scrolling */
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
}
.custom-modal-content::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome and Safari */
}


.custom-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .custom-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative;
    max-width: 90%;
    max-height: 100%;
    object-fit: contain;
  }
}
