.pagecontent {
  display: flex;
  height: 100%;
  margin-left: 2rem;
}

.navigationcol {
  display: flex;
  flex-direction: column;
  width: 20rem;
}

.pagecontentinside {
  margin-top: 2rem;
  -ms-overflow-style: none;
  /* Hide scrollbar on IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar on Firefox */
}

.pagecontentinside::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar on Chrome and Safari */
}

.searchcustomerscol {
  width: 92.5rem;
  margin-top: 1.5rem;
  padding: 0 !important;
  margin-bottom: 1.5rem;
}

.searchbarproduct {
  padding: 0 !important;
  width: 76%;
  margin-left: 22.5rem;
  --background: #ffffff;
  --color: black;
}

.categorybutton {
  width: 192px;
  height: 37px;
  left: 1196px;
  top: 212px;

  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.threedots {
  margin-top: 0.5rem;
  color: black;
  height: 35px;
  width: 50px;
  cursor: pointer;
}

.cardcustomersinfocol {
  padding: 0 !important;
}

.cardcustomersinfocol {
  width: 84.3%;
  margin-left: 11.25rem;
  margin-bottom: 2rem;
}

.cardcustomersinfocol h4 {
  margin-top: 1.3rem;
}

.modalself {
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture {
  margin: 2rem 0rem 0rem 17.5rem;
}

ion-popover {
  --width: 100px;
}

.ion-padding p {
  cursor: pointer;
}

.image-container {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container ion-icon {
  font-size: 60px;
  color: #ccc;
}

.image-upload input[type="file"] {
  display: none;
}

.image-upload {
  margin-bottom: 0.5rem;
}

.photolabel {
  margin-left: 4.7rem;
  width: 130px;
  height: 17px;

  font-style: normal;
  font-weight: 400;
  font-size: 16.8045px;
  line-height: 23px;

  color: #655e5f;
}

.productname {
  width: 145px;
  height: 29px;
  left: 688px;
  top: 219px;

  font-style: normal;
  font-weight: 400;
  font-size: 21.5443px;
  line-height: 29px;
}

.productnameinput {
  box-sizing: border-box;
  width: 749px;
  height: 45px;

  border: 0.913707px solid #bebebe;
  border-radius: 9.13707px;
}

.Unitinput {
  box-sizing: border-box;
  height: 55px;

  border: 0.913707px solid #bebebe;
  border-radius: 9.13707px;
}

.createproductbtn {
  width: 217px;
  height: 51px;
  left: 1220px;
  top: 862px;

  --background: #003b5d;
}

.cancelproductbtn {
  box-sizing: border-box;
  width: 217px;
  height: 51px;
  left: 981px;
  top: 862px;

  color: #003b5d;

  --background: #ffffff;
  border-radius: 2px;
}

.modalactionbtn {
  margin: 2rem 0rem 0rem 18.63rem;
}

.marginpadding {
  padding: 1 !important;
}

.spinner {
  margin-top: 1rem;
  margin-left: 3rem;
}

.profilecontainer {
  width: 50px;
  height: 50px;
}

.closebtncustomerview {
  background: white;
  position: absolute;
  color: black;
  margin: -1.1rem 0rem 0rem 48rem;
}

table {
  margin-top: 2rem;
  width: 100%;
  text-align: center;
}

th {
  background-color: #0083d0;
  color: white;
  height: 47px;
}

td {
  border: 1px solid #0083d0;
  color: black;
}

/* New Added Queries START*/
.CustomerPictureModalMobileCol {
  margin-left: 22%;
}

.InputDesign {
  box-sizing: border-box;
  width: 325px;
  height: 46px;
  color: black;
  background: #ffffff;
  border: 1px solid #bebebe;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}

.WebModalTitle {
  padding: 0 !important;
}

.WebModalTitle h3 {
  margin-top: 0.3rem;
  width: 355px;
  height: 47px;
  left: 313px;
  top: 38px;

  font-style: normal;
  font-weight: 700;
  font-size: 34.2072px;
  line-height: 47px;
  /* identical to box height */

  color: #303133;
}

.XbtnCustomerCol {
  text-align: right;
}

.XbtnCustomer {
  color: black;
  --background: white;
  border: none;
}

.WebModalPictureCol {
  margin-left: 14.5rem;
}

.WebInputDesign {
  box-sizing: border-box;
  width: 300px;
  height: 46px;
  color: black;
  background: #ffffff;
  border: 1px solid #bebebe;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}

/* New Added Queries END*/
/* Media Queries */
@media only screen and (max-width: 1584px) {
  .cardcustomersinfocol {
    margin-left: 0.5%;
    width: 97.5%;
  }

  .searchbarproduct {
    width: 97%;
    margin-left: 1%;
  }
}

@media only screen and (max-width: 768px) {
  .cardcustomersinfocol ion-col {
    text-align: center;
    padding-left: 1.9rem;
    padding-right: 1.9rem;
  }

  .threedotscol {
    text-align: center;
    margin-left: 4.4rem;
  }

  /* modal for android */
  .picture {
    margin: 1rem 0rem 0rem 4rem;
  }

  .productname {
    font-size: 17px;
  }

  .productnameinput {
    width: 100%;
  }

  .modalactionbtn {
    margin: 2rem 0rem 0rem 1.9em;
  }

  .cancelproductbtn {
    width: 8rem;
    border: 1px solid #003b5d;
    border-radius: 2px;
  }

  .createproductbtn {
    width: 8rem;
  }

  .createproduct {
    width: 100%;
  }

  .ion-padding {
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* Hide scrollbar on IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar on Firefox */
  }

  .ion-padding::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar on Chrome and Safari */
  }

  .cardcustomersinfocol ion-col {
    margin-left: 2.5rem;
    margin-right: 2rem;
  }

  .cardcustomersinfocol ion-row {
    width: 100%;
  }

  .cardcustomersinfocol h4 {
    text-align: center;
  }

  .cardproductinfo {
    outline: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .passinput {
    width: 100%;
  }
}
