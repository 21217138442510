.pagecontent {
  display: flex;
  height: 100%;
  margin-left: 2rem;
}
.pagecontentinside {
  overflow-x: auto;
}
.pagecontentinside::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome and Safari */
}

.navigationcol {
  display: flex;
  flex-direction: column;
  width: 20rem;
}
.pagetitle h3 {
  margin-top: -0.5rem;
  width: 355px;
  height: 47px;
  left: 313px;
  top: 38px;

  font-style: normal;
  font-weight: 700;
  font-size: 34.2072px;
  line-height: 47px;
  /* identical to box height */

  color: #303133;
}

.infoinputs {
  margin: 4rem 0rem 0rem 25rem;
}
.passlabel {
  width: 130px;
  height: 17px;
  left: 878px;
  top: 361px;

  font-style: normal;
  font-weight: 400;
  font-size: 16.8045px;
  line-height: 23px;

  color: #655e5f;
}
.passinput {
  box-sizing: border-box;
  width: 325px;
  height: 46px;

  background: #ffffff;
  border: 1px solid #bebebe;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  margin: 0;
}
.savecompanybtn {
  width: 325px;
  height: 53px;
  left: 880px;
  top: 660px;
  padding-left: 3px;

  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}

.salesmembercol {
  margin-left: 21.7rem;
}
.salesmembertitle {
  top: 38px;
  color: #303133;
  font-style: normal;
  font-weight: 700;
  font-size: 34.2072px;
  line-height: 47px;
}

/* Sales Agent CSS */
.addsalesmembercol {
  text-align: right;
}
.addsalesmemberbtn {
  width: 325px;
  height: 53px;
  left: 878px;
  top: 660px;


  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}
.cardsalemember {
  margin-left: 21.7rem;
  width: 80.5%;
}
.agentusernameinput {
  box-sizing: border-box;
  width: 260px;
  height: 46px;


  background: #ffffff;
  border: 1px solid #bebebe;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}
.cancelcreatagent {
  box-sizing: border-box;
  width: 123px;
  height: 51px;
  top: 862px;
  color: black;
  --background: #ffffff;
  border-radius: 2px;
}
.creatagent {
  width: 123px;
  height: 53px;
  left: 878px;
  top: 660px;

  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}
.modalactionbtnagent {
  margin: 8rem 0rem 0rem 17rem;
}

/* Media Queries */
@media only screen and (max-width: 1584px) {
  .infoinputs {
    margin-left: 0rem;
  }
  .salesmembercol {
    margin-left: 0rem;
  }
  .cardsalemember {
    width: 100%;
    margin-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .pagecontentinside {
    overflow-y: auto; /* Enable horizontal scrolling */
    overflow-x: hidden;
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
  }
  .pagecontentinside::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome and Safari */
  }
  .pagetitle h3 {
    margin-left: -0.4rem;
  }
  .picture {
    margin: 0rem 0rem 0rem 4.5rem;
  }
  .photolabel {
    margin: 0rem 0rem 0rem 4.7rem;
  }

  .infoinputs {
    margin: 2rem 0rem 0rem 0rem;
  }
  .savecompanybtn {
    width: 300px;
  }

  /* Sales Agent CSS */
  .addsalesmemberbtn {
    margin-left: 0rem;
    width: 300px;
  }
  .agentusernameinput {
    width: 310px;
  }
  .modalactionbtnagent {
    margin-top: 5rem;
    margin-left: 1.6rem;
  }
}

@media only screen and (max-width: 600px) {
  .passinput {
    width: 100%;
  }
  .addsalesmembercol {
    text-align: center;
  }
  .savecompanycol {
    text-align: center;
  }
}
