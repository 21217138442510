.ReportPCRow {
  padding: 0;
  margin-left: 18%;
}
.ReportPCCol {
  margin-top: -4.5%;
}

.ReportSelectPage {
  position: relative;
  z-index: 101 !important;
  width: 20rem;
  margin-left: 19%;
  --background: none !important;
}

@media screen and (max-width: 1584px) {
  .ReportSelectPage {
    margin-top: 3.5rem;
    margin-left: 0;
  }
  .ReportPCRow {
    padding: 0;
    margin-left: 0;
  }
  .ReportPCCol {
    margin-top: -15%;
  }
}
