.SMPPagetitle  {
  left: 0.5%;
  }
.SMPPagetitle h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 34.2072px;
  line-height: 47px;
  color: #303133;
}
.sortrowSMP{
  left: 0.5%;
}
.SMPExportDataCol{
  text-align: right;
}
.SMPExportDataCol ion-button{
  margin-right: .7%;
  width: 12rem;
  height: 3rem;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  
}
.SMPThreeCol{
text-align: right;
}

ion-popover {
  padding: 3rem;
}

@media screen and (max-width: 768px) {
  .SMPThreeCol{
    text-align: center;
    }
  .sortrowSMP{
    margin-left: -0.3rem;
    margin-top: 3rem;
  }
}

@media (max-width: 412px) {
  .SMPExportDataCol{
    text-align: center;
  }
  .SMPExportDataCol ion-button{

    width: 95%;
 
    
  }
}
