* {
  font-family: "Poppins", sans-serif;
}

@media screen and (min-width: 1024px) {
  .container-grid {
    padding: 0% !important;
    margin: 0% !important;
  }

  .container-col {
    padding: 0 !important;
  }

  .rightcol {
    text-align: center !important;
    padding-top: 20rem !important;
    font-size: 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }

  .leftcol {
    padding: 8rem !important;
  }
}

@media screen and (min-width: 768px) {
  .container-grid {
    padding: 0% !important;
    margin: 0% !important;
  }

  .container-col {
    padding: 0 !important;
  }

  .rightcol {
    padding: 4rem;
    margin: 0;
    background: linear-gradient(112.63deg, #0083d0 8.18%, #23a8f2 93.79%);
    color: white;
    text-align: left;
    font-weight: 700;
  }

  .leftcol {
    padding: 4rem 4rem 4rem 4rem;
  }

  .logoimg {
    width: 10rem;
  }

  .pageheader {
    font-weight: 600;
    font-size: 2rem;
  }

  #emailtxt {
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 6px !important;
  }

  #passwordtxt {
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 6px !important;
  }

  .loginbtn {
    margin: 1rem 0 0 0;
    --background: linear-gradient(270deg, #0083d0 0%, #23a8f2 100%);
    --box-shadow: 0px 0px 7.5914px -2.84677px rgba(0, 0, 0, 0.17);
  }

  .confirmationbox {
    padding: 10px !important;
    --width: 30rem;
  }
  .confirmationmessage {
    padding: 20px !important;
    --width: 20rem;
  }

  .signuprow {
    margin-top: 1rem;
    text-align: center;
  }

  .link {
    color: #23a8f2;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .container-grid {
    padding: 0% !important;
    margin: 0% !important;
  }

  .container-col {
    padding: 0 !important;
  }

  .rightcol {
    padding: 2rem;
    margin: 0;
    background: linear-gradient(112.63deg, #0083d0 8.18%, #23a8f2 93.79%);
    color: white;
    text-align: left;
    font-weight: 700;
  }

  .leftcol {
    padding: 2rem 2rem 2rem 2rem;
  }

  .logoimg {
    width: 10rem;
  }

  .pageheader {
    font-weight: 600;
    font-size: 2rem;
  }

  #emailtxt {
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 6px !important;
  }

  #passwordtxt {
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 6px !important;
  }

  .loginbtn {
    margin: 1rem 0 0 0;
    --background: linear-gradient(270deg, #0083d0 0%, #23a8f2 100%);
    --box-shadow: 0px 0px 7.5914px -2.84677px rgba(0, 0, 0, 0.17);
  }

  .confirmationbox {
    padding: 10px !important;
    --width: 20rem;
  }
  .confirmationmessage {
    padding: 8px !important;
    --width: 20rem;
  }

  .signuprow {
    margin-top: 1rem;
    text-align: center;
  }

  .link {
    color: #23a8f2;
    text-decoration: underline;
    cursor: pointer;
  }
}
