.staticnavbar {
  width: 20rem;
  --ion-background-color: #0083d0;
  --ion-text-color: #e9edf2;
}

.logocol {
  display: block;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
.listnav {
  --ion-item-background: transparent;
  margin: 2rem 0rem 0rem 3rem;
}
.listnav::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome and Safari */
}
.bottomlistnav {
  margin: 17rem 0rem 0rem 0rem;
}
.burgerlistnav {
  margin: 2rem 0rem 0rem 2rem;
}
.bottomlistburgernav {
  margin: 7rem 0rem 0rem 0rem;
}
.menunav {
  height: 4rem;
}

ion-item {
  margin: 0rem 0rem 1.5rem 0rem;
  font-style: normal;
  font-weight: 400;
  font-size: 17.2256px;
  line-height: 23px;
  letter-spacing: -0.025em;
  cursor: pointer;
}
.profilepic {
  margin-right: 0.8rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 1584px) {
  .staticnavbar {
    display: none;
  }
  .burgerlistnav {
    --ion-item-background: transparent !important;
    --ion-text-color: #e9edf2;
  }
  .bugermenu {
    --ion-background-color: #0083d0;
  }
}
@media screen and (min-width: 1585px) {
  .bugermenu {
    display: none;
  }
  .listnav {
    --ion-item-background: transparent;
    --ion-text-color: #e9edf2;
  }
}
@media (max-width: 480px) {
}
