@media screen and (max-width: 1586px) {

  .ProductCreatePictureCol {
    margin: 2% 35% 0% 20%;
  }
  .imageupload input[type="file"] {
    display: none;
  }
  .imageupload {
    margin-bottom: 0.5rem;
  }
  
  .imagecontainer {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: blue !important;
  }
  .iconweb {
    margin-left: 1.5rem;
    text-align: center !important;
  }
  .ProductCreatePictureLabel {
    margin-left: 10.6rem !important;
    }
  
    .modalcontentinputweb {
      padding: 0.5rem 1rem 0rem 1rem !important;
    }
    
  .inputselectweb {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    /* padding: 0.2rem !important; */
  }

  .inputselectweb2 {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.2rem !important;
  }

  .modalcontentticbox {
    padding: 1rem 1rem 0rem 1rem !important;
  }
  .inputboxweb {
    box-sizing: border-box;
    width: 749px;
    height: 45px;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.5rem !important;
  }

  .inputselectweb {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    /* padding: 0.2rem !important; */
  }

  .inputselectweb2 {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.2rem !important;
  }
}
@media screen and (min-width: 1586px) {
  .pagetitle {
    margin: 4.5rem 0rem 0rem 1rem;
  }

  .createprdctbtncol {
    padding: 1rem 0rem 0rem 35rem !important;
    margin: 4rem 0rem 0rem 1rem !important;
    justify-content: right !important;
  }

  .searchscol {
    padding: 0rem 4rem 0rem 21rem !important;
  }

  .productlistcol {
    padding: 1rem 4rem 0rem 21rem !important;
  }

  .cardcontents {
    padding: 0;
  }

  .txtcol {
    text-align: center !important;
    padding-top: 2.5rem !important;
  }

  .catcol {
    padding-top: 1.5rem !important;
  }

  .dotscol {
    padding: 0rem !important;
    text-align: center !important;
  }

  .dotsbtn {
    height: 35px !important;
    width: 50px !important;
  }

  .popovercontent {
    overflow-y: scroll !important;
    -ms-overflow-style: none !important; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none !important; /* Hide scrollbar on Firefox */
  }
  .popovercontent::-webkit-scrollbar {
    display: none !important; /* Hide scrollbar on Chrome and Safari */
  }

  .ProductCreatePictureCol {
    margin: 2% 35% 0% 28%;
  }

  .imagecontainer {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: blue !important;
  }

  .iconweb {
    margin-left: 1.5rem;
    text-align: center !important;
  }

  .imagecontainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imagecontainer ion-icon {
    font-size: 60px;
    color: #ccc;
    padding: 0%;
    margin-right: 5.4rem;
  }
  .imageupload input[type="file"] {
    display: none;
  }
  .imageupload {
    margin-bottom: 0.5rem;
  }

  .ProductCreatePictureLabel {
  margin-left: 9.2rem !important;
  }

  .modalcontentinputweb {
    padding: 0.5rem 1rem 0rem 1rem !important;
  }

  .inputboxweb {
    box-sizing: border-box;
    width: 749px;
    height: 45px;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.5rem !important;
  }

  .inputselectweb {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    /* padding: 0.2rem !important; */
  }

  .inputselectweb2 {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.2rem !important;
  }

  .modalcontentticbox {
    padding: 1rem 1rem 0rem 1rem !important;
  }

  .salelabel {
    padding: 0.5rem;
  }

  .confirmationboxs {
    padding: 10px !important;
    --width: 20rem;
  }
  .confirmationmessages {
    padding: 8px !important;
    --width: 20rem;
  }
 
  .modalselfs {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .androidmodalphotolabel{
    margin-left: 7rem;
  }
}

@media screen and (min-width: 768px) {
  .pagetitle {
    margin: 4.5rem 0rem 0rem 1rem;
  }

  .createprdctbtncol {
    padding: 1rem;
    margin: 3rem 0rem 0rem 1rem;
    justify-content: right !important;
  }

  .txtcol {
    text-align: center !important;
    /* padding: 2rem !important; */
  }

  .dotscol {
    padding: 2rem !important;
    text-align: center !important;
  }

  .catcol {
    padding-top: 1.5rem !important;
  }

  .dotsbtn {
    height: 35px !important;
    width: 50px !important;
  }

  .popovercontent {
    overflow-y: scroll !important;
    -ms-overflow-style: none !important; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none !important; /* Hide scrollbar on Firefox */
  }
  .popovercontent::-webkit-scrollbar {
    display: none !important; /* Hide scrollbar on Chrome and Safari */
  }

  .picturecol {
    margin: 2% 22% 0% 22%;
  }

  .imagecontainer {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: blue !important;
  }

  .imagecontainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imagecontainer ion-icon {
    font-size: 60px;
    color: #ccc;
    padding: 0%;
    margin-right: 5.4rem;
  }
  .imageupload input[type="file"] {
    display: none;
  }
  .imageupload {
    margin-bottom: 0.5rem;
  }

  .photo-label {
    margin-left: 4.7rem;
    width: 130px;
    height: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 16.8045px;
    line-height: 23px;
    color: black;
  }

  .modalcontentinput {
    padding: 0.5rem 1rem 0rem 1rem !important;
  }

  .inputbox {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.5rem !important;
  }

  .inputselect {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 1rem !important;
    margin: 0 !important;
  }

  .modalcontentticbox {
    padding: 1rem 1rem 0rem 1rem !important;
  }

  .salelabel {
    padding: 0.5rem;
  }

  .btncol {
    padding: 1rem;
  }

  .confirmationboxs {
    padding: 10px !important;
    --width: 20rem;
  }
  .confirmationmessages {
    padding: 8px !important;
    --width: 20rem;
  }
}

@media screen and (max-width: 767px) {
  .pagetitle {
    margin: 4.5rem 0rem 0rem 1rem;
  }

  .txtcol {
    text-align: center !important;
    padding: 5px !important;
  }

  .dotscol {
    padding: 1rem 0rem 0rem 0rem !important;
    text-align: center !important;
  }

  .dotsbtn {
    height: 35px !important;
    width: 50px !important;
  }

  .popovercontent {
    overflow-y: scroll !important;
    -ms-overflow-style: none !important; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none !important; /* Hide scrollbar on Firefox */
  }
  .popovercontent::-webkit-scrollbar {
    display: none !important; /* Hide scrollbar on Chrome and Safari */
  }

  .picturecol {
    margin: 2% 22% 0% 22%;
  }

  .imagecontainer {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: blue !important;
  }

  .imagecontainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imagecontainer ion-icon {
    font-size: 60px;
    color: #ccc;
    padding: 0%;
    margin-right: 5.4rem;
  }
  .imageupload input[type="file"] {
    display: none;
  }
  .imageupload {
    margin-bottom: 0.5rem;
  }

  .photo-label {
    margin-left: 4.7rem;
    width: 130px;
    height: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 16.8045px;
    line-height: 23px;
    color: black;
  }

  .modalcontentinput {
    padding: 0.5rem 1rem 0rem 1rem !important;
  }

  .inputbox {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.5rem !important;
  }

  .inputselect {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 1rem !important;
    margin: 0 !important;
  }

  .modalcontentticbox {
    padding: 1rem 1rem 0rem 1rem !important;
  }

  .salelabel {
    padding: 0.5rem;
  }

  .btncol {
    padding: 1rem;
  }

  .confirmationboxs {
    padding: 10px !important;
    --width: 20rem;
  }
  .confirmationmessages {
    padding: 8px !important;
    --width: 20rem;
  }
}
