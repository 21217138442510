@media screen and (min-width: 1586px) {
  .pagetitle {
    margin: 6rem 0rem 0rem 22rem !important;
  }

  .sortcol {
    padding: 0rem 4rem 0rem 22rem !important;
  }

  .searchcol {
    padding: 0rem 4rem 0rem 22rem !important;
  }

  .actionlistcol {
    padding: 1rem 4rem 0rem 22rem !important;
  }

  .actionslist {
    margin-bottom: 1.5rem;
    display: flex; /* Make the buttons appear in a row */
    overflow-x: auto; /* Enable horizontal scrolling */
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
  }
  .actionslist::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome and Safari */
  }
  .actionslist ion-button {
    --background: #ffffff;
    color: black;
    width: 8rem;
    height: 3rem;
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .exportbtncol {
    padding: 1rem 4rem 0rem 14rem !important;
  }

  .exportbtn {
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
    /* --background: #003b5d; */
  }

  .cardlistcol {
    padding: 1rem 4rem 0rem 22rem !important;
  }

  .listcol {
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .lowerbtn-txtmodal {
    text-align: right;
  }

  .lowerbtn-txtmodal2 {
    text-align: center;
  }

  .twocolinputconfirm{
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .textareaconfirm{
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}
.pagination{
  margin-left: 4.5px;
}

@media screen and (min-width: 768px) {

  .tableStyles{
    width: 100% !important;
    height: 5px;
  }

  .pagetitle {
    margin: 6rem 0rem 0rem 1.5rem;
  }

  .actionlistcol {
    padding: 1rem 1rem 0rem 1rem;
  }

  .actionslist {
    margin-bottom: 1.5rem;
    display: flex; /* Make the buttons appear in a row */
    overflow-x: auto; /* Enable horizontal scrolling */
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
  }
  .actionslist::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome and Safari */
  }
  .actionslist ion-button {
    --background: #ffffff;
    color: black;
    width: 8rem;
    height: 3rem;
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .exportbtncol {
    padding-top: 1rem;
  }

  .exportbtn {
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
    /* --background: #003b5d; */
  }

  .lowerbtn-txtmodal {
    text-align: right;
  }

  .lowerbtn-txtmodal2 {
    text-align: center;
  }

}

@media screen and (max-width: 767px) {

  .tableStyles{
    width: 700px !important;
    height: 5px;
  }

  .trStyles{
    font-size: 12px !important;
  }

  .pagetitle {
    margin: 4.5rem 0rem 0rem 1rem;
  }

  .actionlistcol {
    padding: 1rem 1rem 0rem 1rem;
  }

  .actionslist {
    margin-bottom: 1.5rem;
    display: flex; /* Make the buttons appear in a row */
    overflow-x: auto; /* Enable horizontal scrolling */
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
  }
  .actionslist::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome and Safari */
  }
  .actionslist ion-button {
    --background: #ffffff;
    color: black;
    width: 8rem;
    height: 3rem;
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .exportbtncol {
    padding: 0.5rem;
  }

  .exportbtn {
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
    /* --background: #003b5d; */
  }

  .lowerbtn-txtmodal {
    text-align: center;
  }

  .lowerbtn-txtmodal2 {
    text-align: center;
  }
}
