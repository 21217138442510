@media screen and (min-width: 1586px) {
  .pagetitle {
    margin: 4.5rem 0rem 0rem 1rem;
  }

  .contentgrid {
    padding: 0 20% 0 40%;
  }

  .picturecol {
    margin: 2% 22% 0% 22%;
  }

  .imagecontainer {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: blue !important;
    margin-left: 25%;
  }

  .imagecontainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imagecontainer ion-icon {
    font-size: 60px;
    color: #ccc;
    padding: 0%;
    margin: 0%;
  }
  .imageupload input[type="file"] {
    display: none;
  }
  .imageupload {
    margin-bottom: 0.5rem;
  }

  .txtcolinput {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.5rem !important;
  }

  .confirmationboxss {
    padding: 10px !important;
    --width: 20rem;
  }
  .confirmationmessagess {
    padding: 8px !important;
    --width: 20rem;
  }
}

@media screen and (min-width: 768px) {
  .pagetitle {
    margin: 4.5rem 0rem 0rem 1rem;
  }

  .picturecol {
    margin: 2% 22% 0% 22%;
  }

  .imagecontainer {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: blue !important;
    margin-left: 25%;
  }

  .imagecontainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imagecontainer ion-icon {
    font-size: 60px;
    color: #ccc;
    padding: 0%;
    margin: 0%;
  }
  .imageupload input[type="file"] {
    display: none;
  }
  .imageupload {
    margin-bottom: 0.5rem;
  }

  .txtcolinput {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.5rem !important;
  }

  .confirmationboxss {
    padding: 10px !important;
    --width: 20rem;
  }
  .confirmationmessagess {
    padding: 8px !important;
    --width: 20rem;
  }
}

@media screen and (max-width: 767px) {
  .pagetitle {
    margin: 4.5rem 0rem 0rem 1rem;
  }

  .picturecol {
    margin: 2% 22% 0% 22%;
  }

  .imagecontainer {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: blue !important;
  }

  .imagecontainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imagecontainer ion-icon {
    font-size: 60px;
    color: #ccc;
    padding: 0%;
    margin: 0%;
  }
  .imageupload input[type="file"] {
    display: none;
  }
  .imageupload {
    margin-bottom: 0.5rem;
  }

  .txtcolinput {
    box-sizing: border-box;
    color: black;
    border: 0.913707px solid #bebebe;
    border-radius: 9.13707px;
    padding: 0.5rem !important;
  }

  .confirmationboxss {
    padding: 10px !important;
    --width: 20rem;
  }
  .confirmationmessagess {
    padding: 8px !important;
    --width: 20rem;
  }
}
