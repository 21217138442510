/* *{
  border: 1px solid red;
} */
.PPPagetitle {
  left: 0.5%;
}
.PPPagetitle h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 34.2072px;
  line-height: 47px;
  color: #303133;
}
.actionslistrowproductperformance {
  margin-left: 0.5%;
}
.filterlistproductperformance {
  width: 86%;
  margin-bottom: 1.5rem;
  display: flex; /* Make the buttons appear in a row */
  overflow-x: auto; /* Enable horizontal scrolling */
}
.filterlistproductperformance::-webkit-scrollbar {
  background: rgba(35, 168, 242, 0.71);
  height: 5px;
}
.filterlistproductperformance::-webkit-scrollbar-thumb {
  background: blue;;
}


.filterlistproductperformance ion-button {
  --background: #ffffff;
  color: black;
  width: 20rem;
  height: 3rem;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.PPExportDataCol {
  text-align: right;
}
.PPExportData {
  margin-right: 1.5%;
  width: 12rem;
  height: 3rem;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.categoryppbtn {
  width: auto;
}
@media screen and (max-width: 1825px) {
.PPExportDataCol {
  text-align: left;
}
.filterlistproductperformance {
  width: 99%;
}
}
@media screen and (max-width: 1092px) {
  .PPExportDataCol {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .PPExportDataCol {
    text-align: center;
  }
  .PPExportData {
    width: 95%;
  }
  .filterlistproductperformance {
    width: 97%;
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
  }
  .filterlistproductperformance::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome and Safari */
  }
}

@media (max-width: 412px) {
  .PPExportDataCol {
    text-align: center;
  }
  .PPExportData {
    width: 95%;
  }
}
